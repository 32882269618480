import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const querymp = graphql`
  query JobsQuery($slug: String!) {
    allStrapiJobs(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          slug
          order
        }
      }
    }
    strapiJobs(slug: { eq: $slug }) {
      slug
      title
      description
      content
    }
    strapiJobsPage {
      title
      slug
    }
  }
`

const Jobs = ({ data }) => {
  const jobs = data.strapiJobs
  const jobsPage = data.strapiJobsPage

  function markupJobsContent() {
    return { __html: jobs.content }
  }

  return (
    <Layout>
      <Seo
        title={jobs.title + " | Vacational Studies"}
        description={jobs.description}
      />

      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Submenu</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  {data.allStrapiJobs.edges.map((jobs, i) => {
                    return (
                      <li
                        className="subnav__item"
                        key={`page__${jobs.node.slug}`}
                      >
                        <Link
                          className="subnav__link"
                          to={`/jobs/${jobs.node.slug}`}
                        >
                          {jobs.node.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <nav
        id="breadcrumbs"
        className="breadcrumbs text-sm container max-width-adaptive-sm margin-top-xl"
        aria-label="Breadcrumbs"
      >
        <ol className="flex flex-wrap gap-xxs">
          <li className="breadcrumbs__item">
            <Link className="color-inherit" to={`./${jobsPage.slug}`}>
              {jobsPage.title}
            </Link>
            <span
              className="color-contrast-low margin-left-xxs"
              aria-hidden="true"
            >
              /
            </span>
          </li>

          <li className="breadcrumbs__item" aria-current="page">
            {jobs.title}
          </li>
        </ol>
      </nav>

      <div
        id="content"
        className="container max-width-adaptive-sm padding-top-lg padding-bottom-xxl position-relative z-index-1"
      >
        <article className="article text-component">
          <h1 className="text-xxxl">{jobs.title}</h1>
          <p className="text-md color-contrast-medium">{jobs.description}</p>
          <div dangerouslySetInnerHTML={markupJobsContent()} />
          <Link
            className="color-inherit text-decoration-none btn btn--primary"
            to={`/jobs/application`}
          >
            Apply for this Job
          </Link>
        </article>
      </div>
    </Layout>
  )
}

export default Jobs
